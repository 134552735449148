

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'MULISH';
    src: url("/public/fonts/Mulish-ExtraBoldItalic.ttf"); 
    /*font-family: 'Lato';
    src: url("/public/fonts/Lato-Bold.ttf");*/
}

html {
    scroll-behavior: smooth;
}
.desaturate:hover {
    filter: saturate(100%) !important;
}

body:has(.leader:hover) .leader {
    filter:saturate(10%);  
}

.w-2screen {
    width: 200vw;
}
#navbar {
    transition: all 1s;
}

.trapezoid-clip {
    clip-path: polygon(0 0, 50% 0, 80% 100%, 0% 100%);
}

.scalein {
    scale: 100;
    transition: scale cubic-bezier(0.215, 0.610, 0.355, 1) 2s;
}


.v-movein {
    opacity: 100;
    translate: 0px -60px;
    transition: all cubic-bezier(0.215, 0.610, 0.355, 1) 2s;
    transition-delay: 400ms;
}



.opIn {
    opacity: 0;
    opacity: 100;
    transition: all cubic-bezier(0.215, 0.610, 0.355, 1) 2s;
    transition-delay: 400ms;
}